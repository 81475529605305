<template>
  <div>
    <div
      v-show="!x_link_option_enable"
      class="text-subtitle-2 pa-1 orange--text orange lighten-5"
    >
      ご利用には「X連携」オプションの契約が必要です。
    </div>
    <div class="icon-description-container">
      <div class="text-subtitle-2 pa-1 d-flex nowrap">
        <span class="d-flex align-center">【投稿状況】</span>
        <span class="d-flex align-center mr-3">{{
          `本日の投稿 ${postInfo.today} / ${postInfo.today_limit} 回`
        }}</span>
        <!-- <span class="d-flex align-center mr-3"
          >{{
            `${postInfo.thisMonth}月投稿 ${postInfo.month} / ${postInfo.month_limit} 回`
          }}
        </span> -->
        <span class="d-flex align-center mr-3">{{
          `連携アカウント ${postInfo.account} / ${postInfo.account_limit} 個`
        }}</span>
        <span class="d-flex align-center mr-3"
          >※1日の投稿数は0時にリセットします。</span
        >
      </div>
    </div>
    <div style="position: relative">
      <v-overlay
        :value="!x_link_option_enable"
        absolute
        opacity="0.2"
        z-index="5"
      ></v-overlay>
      <template v-if="shopSites.length">
        <v-tabs v-model="refSiteTabs">
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            v-for="shopSite in shopSites"
            :key="shopSite.id"
            class="font-weight-bold"
            >{{ shopSite.name }}</v-tab
          >
        </v-tabs>
        <v-tabs-items v-model="refSiteTabs" touchless>
          <v-tab-item
            v-for="shopSite in shopSites"
            :key="shopSite.id"
            :eager="true"
          >
            <component
              v-bind:is="shopSite.component"
              ref="refShopSiteXComponent"
              :post_info="postInfo"
              :com_data="shopSite"
              @get_post_info="getPostInfo"
            ></component>
          </v-tab-item>
        </v-tabs-items>
      </template>
    </div>
  </div>
</template>

<script>
import {
  ref,
  set,
  reactive,
  onMounted,
  onUnmounted,
  watch,
  toRefs,
} from "@vue/composition-api";
import { useRouter } from "@/utils";
import { repositoryFactory } from "@/repository/repositoryFactory";
import xComComponent from "./component/xCom.vue";
import shopSiteXComponent from "./shopSiteX.vue";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilDate from "@/common/utilDate.js";
import utilFunc from "@/common/utilFunc.js";
import _ from "lodash";

export default {
  components: {
    xComComponent,
    shopSiteXComponent,
  },
  setup(props, ctx) {
    const synchroRepository = repositoryFactory.get("synchro");
    const shopSiteXAccountRepository =
      repositoryFactory.get("shopSiteXAccounts");
    const shopSitePostCountRepository =
      repositoryFactory.get("shopSitePostCounts");

    const { router } = useRouter();
    const refShopSiteXComponent = ref();
    const refSiteTabs = ref();
    const refresh = ref();

    const state = reactive({
      x_link_option_enable: true,
      overlay: false,
      errorTab: null,
      errorCom: null,
      shopSites: [],
      casts: [],
      baseComponents: {},
      postInfo: {
        today: 0,
        today_limit: 0,
        month: 0,
        month_limit: 0,
        account: 0,
        account_limit: 0,
        thisMonth: 0,
        is_post: true,
      },
    });

    let setIntervalId;

    const getPostInfo = async () => {
      const now = new Date();
      // 投稿状況/契約情報 取得
      const currentShop = store.getters["shops/currentShop"];
      const count = await utilFunc.getValidOptionCount(
        currentShop.data.valid_option,
        setting.plan_code_x_link
      );
      // 今月を取得
      state.postInfo.thisMonth = now.getMonth() + 1;
      if (count > 0) {
        state.postInfo.today_limit = count * setting.x_com_max_post_day;
        state.postInfo.month_limit = count * setting.x_com_max_post_month;
        state.postInfo.account_limit = count * setting.x_com_max_link;
      }
      // 件数取得
      let params = {
        shop_id: currentShop.id,
        today: utilDate.getDateFormatLong(now),
      };
      await shopSitePostCountRepository
        .get_all_count(params)
        .then((response) => {
          if (response.data) {
            state.postInfo.today = response.data.today;
            state.postInfo.month = response.data.month;
          }
        })
        .catch((error) => {
          throw (
            "ERROR:shopSitePostCountRepository.get_all_count (" + error + ")"
          );
        });
      params = {
        shop_id: currentShop.id,
        shop_site_ids: state.shopSites.map((x) => x.id),
      };
      await shopSiteXAccountRepository
        .get_account_count(params)
        .then((response) => {
          if (response.data) {
            state.postInfo.account = response.data.count;
          }
        })
        .catch((error) => {
          throw (
            "ERROR:shopSiteXAccountRepository.get_account_count (" + error + ")"
          );
        });
      if (
        state.postInfo.today >= state.postInfo.today_limit ||
        state.postInfo.month >= state.postInfo.month_limit
      ) {
        state.postInfo.is_post = false;
      }
    };

    // タイマー処理(毎分チェック)
    const timer = async () => {
      store.dispatch("x_link/refreshPostInfo");
    };

    const init = async () => {
      // 更新媒体で営業タイプの媒体を取得
      const tmpShopSite = store.getters["shop_sites/getShopSiteList"];
      for (let i = 0; i < tmpShopSite.length; i++) {
        if (
          tmpShopSite[i].sites_type == setting.site_type_sns &&
          tmpShopSite[i].is_active &&
          tmpShopSite[i].sites_category == setting.site_category_sns &&
          tmpShopSite[i].sites_com_name == "X"
        ) {
          // 媒体毎のコンポーネントに渡すデータを生成
          const prefix =
            setting.cast_props_only +
            "__" +
            tmpShopSite[i].id +
            "__" +
            tmpShopSite[i].sites_com_name;
          // データを渡す
          state.shopSites.push({
            id: tmpShopSite[i].id,
            sites_id: tmpShopSite[i].sites_id,
            name: tmpShopSite[i].name,
            color: tmpShopSite[i].color,
            sites_com_name: tmpShopSite[i].sites_com_name,
            is_active: tmpShopSite[i].is_active,
            component: shopSiteXComponent,
            refresh: 0,
          });
        }
      }

      // 投稿状況/契約情報 取得
      await getPostInfo();
    };

    init();

    // 初期化処理
    onMounted(async () => {
      // 店舗チェック falseの場合ログアウトになる
      const currentShop = store.getters["shops/currentShop"];
      const checkResult = await utilFunc.checkShop(currentShop.id, router);
      if (!checkResult) return;
      // 契約チェック
      if (
        (await utilFunc.getValidOptionCount(
          checkResult.valid_option,
          setting.plan_code_x_link
        )) > 0
      ) {
        state.x_link_option_enable = true;
      } else {
        state.x_link_option_enable = false;
      }

      // タイマー開始
      setIntervalId = setInterval(timer, 60000);

      // SNS認証処理
      await authSns();
    });

    // タイマー終了
    onUnmounted(() => clearInterval(setIntervalId));

    watch(
      () => store.getters["x_link/getRefreshKey"],
      (newVal) => {
        getPostInfo();
      }
    );

    const tabMove = (tabIndex) => {
      refSiteTabs.value = tabIndex;
      refresh.value++;
    };

    const setOverlay = (overlay) => {
      state.overlay = overlay;
    };

    // X認証処理
    const authSns = async () => {
      // X認証処理
      const query = ctx.root.$route.query;

      // X認証処理(v1版)
      if (query["oauth_token"] && query["oauth_verifier"]) {
        store.dispatch("loadingIcon/showIcon"); // ローディング表示

        // stateを元にshop_siteを取得する
        const checkShopSiteXAccount = await shopSiteXAccountRepository
          .get_x_state(query["oauth_token"])
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
            throw (
              "ERROR:shopSiteXAccountRepository.get_x_state (" + error + ")"
            );
          });

        if (!checkShopSiteXAccount) {
          alert("Xの認証処理に問題がありました。再度連携してください。");
        } else {
          // shop_id検証
          if (
            store.getters["shops/currentShop"].id !=
            checkShopSiteXAccount.shop_id
          ) {
            alert(
              "Xの認証処理(店舗)に問題がありました。再度連携してください。"
            );
          } else {
            // shop_site_id検証 & タブを移動する
            const index = state.shopSites.findIndex(
              (x) => x.id == checkShopSiteXAccount.shop_site_id
            );
            if (index != -1) {
              tabMove(index);

              const params = {
                shop_id: checkShopSiteXAccount.shop_id,
                shop_site_id: checkShopSiteXAccount.shop_site_id,
                shop_site_x_account_id: checkShopSiteXAccount.id,
                url: window.location.href,
              };
              const result = await synchroRepository
                .get_access_token(params)
                .then((response) => {
                  return response.data;
                })
                .catch((error) => {
                  store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
                  throw (
                    "ERROR:synchroRepository.get_access_token (" + error + ")"
                  );
                });

              if (result.status) {
                // 連携完了
                alert(result.message);
                // 画面をリフレッシュ（連携が切れるアカウントがあるかもしれないので全タブをリフレッシュする）
                for (let i = 0; i < state.shopSites.length; i++) {
                  set(state.shopSites, i, {
                    ...state.shopSites[i],
                    refresh: state.shopSites[i].refresh + 1,
                  });
                }
                // 投稿状況/契約情報 取得
                await getPostInfo();
              } else {
                alert("Xの認証処理に問題がありました。再度連携してください。");
              }
            } else {
              alert(
                "Xの認証処理(媒体)に問題がありました。再度連携してください。"
              );
            }
          }
        }
        store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
      } else if (query["denied"]) {
        alert("Xの認証処理に問題がありました。処理を中止します。");
      }

      current_url_cut();

      // X認証処理(v2版)
      // if (query["state"]) {
      //   // queryにerrorが存在する場合、処理を中断する
      //   console.log("query", query);
      //   if (query["error"]) {
      //     alert("Xの認証処理に問題がありました。処理を中止します。");
      //     return;
      //   }

      //   store.dispatch("loadingIcon/showIcon"); // ローディング表示

      //   // stateを元にshop_siteを取得する
      //   const checkShopSite = await shopSitesRepository
      //     .get_x_state(query["state"])
      //     .then((response) => {
      //       return response.data;
      //     })
      //     .catch((error) => {
      //       store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
      //       throw (
      //         "ERROR:synchroRepository.sync_get_authorization_url (" +
      //         error +
      //         ")"
      //       );
      //     });
      //   if (!checkShopSite) {
      //     alert("Xの認証処理に問題がありました。再度連携してください。");
      //   } else {
      //     // shop_id検証
      //     if (store.getters["shops/currentShop"].id != checkShopSite.shop_id) {
      //       alert(
      //         "Xの認証処理(店舗)に問題がありました。再度連携してください。"
      //       );
      //     } else {
      //       // URLを取得する
      //       const params = {
      //         shop_id: checkShopSite.shop_id,
      //         shop_site_id: checkShopSite.id,
      //         url: window.location.href,
      //       };
      //       const result = await synchroRepository
      //         .get_access_token(params)
      //         .then((response) => {
      //           console.log("response", response);
      //           return response.data;
      //         })
      //         .catch((error) => {
      //           store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
      //           throw (
      //             "ERROR:synchroRepository.sync_get_authorization_url (" +
      //             error +
      //             ")"
      //           );
      //         });

      //       if (result.status) {
      //         await ModelShopSite.setStore();
      //         refresh.value++;
      //         alert("X認証処理が完了しました。");
      //       } else {
      //         alert("Xの認証処理に問題がありました。再度連携してください。");
      //       }
      //     }
      //   }
      // }
    };

    const current_url_cut = () => {
      // 現在のURLを取得
      const currentUrl = window.location.href;
      // ?の位置を取得
      const queryIndex = currentUrl.indexOf("?");
      // ?が存在する場合
      if (queryIndex !== -1) {
        // ?から前の部分を取得
        const newUrl = currentUrl.substring(0, queryIndex);
        // URLを更新
        history.replaceState(null, "", newUrl);
      }
    };

    // const xLogin = async () => {
    //   state.linkProcess = true;

    //   const index = refSiteTabs.value;
    //   await ctx.refs.refShopSiteXComponent[index].xLogin();
    // };

    return {
      setting,
      refShopSiteXComponent,
      refSiteTabs,
      tabMove,
      refresh,
      ...toRefs(state),
      setOverlay,
      getPostInfo,
    };
  },
};
</script>

<style scoped>
.icon-description-container {
  overflow-x: auto;
  /* スクロールバーを非表示にする */
  -ms-overflow-style: none; /* IEとEdge */
  scrollbar-width: none; /* Firefox */
}
.nowrap {
  white-space: nowrap;
}
</style>
